exports.components = {
  "component---src-dynamic-pages-blog-article-tsx": () => import("./../../../src/dynamic-pages/blog-article.tsx" /* webpackChunkName: "component---src-dynamic-pages-blog-article-tsx" */),
  "component---src-dynamic-pages-expret-slug-tsx": () => import("./../../../src/dynamic-pages/expret-slug.tsx" /* webpackChunkName: "component---src-dynamic-pages-expret-slug-tsx" */),
  "component---src-dynamic-pages-features-tsx": () => import("./../../../src/dynamic-pages/features.tsx" /* webpackChunkName: "component---src-dynamic-pages-features-tsx" */),
  "component---src-dynamic-pages-template-preview-tsx": () => import("./../../../src/dynamic-pages/template-preview.tsx" /* webpackChunkName: "component---src-dynamic-pages-template-preview-tsx" */),
  "component---src-dynamic-pages-template-slug-tsx": () => import("./../../../src/dynamic-pages/template-slug.tsx" /* webpackChunkName: "component---src-dynamic-pages-template-slug-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-experts-index-tsx": () => import("./../../../src/pages/experts/index.tsx" /* webpackChunkName: "component---src-pages-experts-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-index-tsx": () => import("./../../../src/pages/investors/index.tsx" /* webpackChunkName: "component---src-pages-investors-index-tsx" */),
  "component---src-pages-partners-program-index-tsx": () => import("./../../../src/pages/partners-program/index.tsx" /* webpackChunkName: "component---src-pages-partners-program-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-request-a-demo-index-tsx": () => import("./../../../src/pages/request-a-demo/index.tsx" /* webpackChunkName: "component---src-pages-request-a-demo-index-tsx" */),
  "component---src-pages-templates-index-tsx": () => import("./../../../src/pages/templates/index.tsx" /* webpackChunkName: "component---src-pages-templates-index-tsx" */)
}

